import { HYDRATE } from 'next-redux-wrapper'

export const shuffleArray = array => {
  let result = [ ...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = result[i];
    result[i] = result[j];
    result[j] = temp;
  }

  return result;
}

export const transform2DataObj = (data, shuffle=false) => {
  const dataObject = {};
  data.forEach((item, index) => {
    const { idProyect } = item;
    if (!shuffle) {
      dataObject[idProyect] = item;
    } else {
      dataObject[index] = item;
    }
  })
  return dataObject;
}


export const ACTIONS = {
  GET_PROJECTS: 'MAIN/GET_PROJECTS',
  SET_PROJECTS: 'MAIN/SET_PROJECTS',
  GET_PROJECTS_DONE: 'MAIN/GET_PROJECTS_DONE',
  GET_PROJECTS_KIND: 'MAIN/GET_PROJECTS_KIND',
  GET_PROJECTS_KIND_DONE: 'MAIN/GET_PROJECTS_KIND_DONE',
  SET_PROJECTS_KIND: 'MAIN/SET_PROJECTS_KIND',
  SET_HEADER_OPTION: 'MAIN/SET_HEADER_OPTION',
  /* project */
  GET_PROJECT: 'MAIN/GET_PROJECT',
  SET_SINGLE_PROJECT: 'MAIN/SET_SINGLE_PROJECT',
  PROJECT_FILTER: 'MAIN/PROJECT_FILTER',
  GET_RELATED_PROJECT: 'MAIN/GET_RELATED_PROJECT',
  SET_RELATED_PROJECT: 'MAIN/SET_RELATED_PROJECT',
  /* SEO */
  GET_CURRENT_SEO: 'MAIN/GET_CURRENT_SEO',
  SET_CURRENT_SEO: 'MAIN/SET_CURRENT_SEO',
  /* nextjs */
  HYDRATE: 'MAIN/HYDRATE',
}

export const actions = {
  /* projects */
  getProjects: (kind, all) => ({ type: ACTIONS.GET_PROJECTS, params: {kind, all} }),
  setProjects: (type, data) => ({ type: ACTIONS.SET_PROJECTS, payload:{ type, data }}),
  getProjectsKind: () => ({ type: ACTIONS.GET_PROJECTS_KIND }),
  getProjectsKindDone: (kinds) => ({ type: ACTIONS.GET_PROJECTS_KIND_DONE, kinds }),
  setProjectsKind: (payload) => ({ type: ACTIONS.SET_PROJECTS_KIND, payload }),
  getRelatedProject: (data) => ({ type:ACTIONS.GET_RELATED_PROJECT, data }),
  setRelatedProject: (data) => ({ type:ACTIONS.SET_RELATED_PROJECT, data }),
  /* single project */
  getSingleProject: id => ({ type: ACTIONS.GET_PROJECT, id}),
  setSingleProject: ({id, data}) => ({ type: ACTIONS.SET_SINGLE_PROJECT, payload: { id, data }}),
  /* header */
  setHeaderOption: (option) => ({ type: ACTIONS.SET_HEADER_OPTION, option }),
  setClientState: (payload) => ({ type: ACTIONS.SET_CLIENT_STATE, payload }),
  /* SSEO */
  getCurrentSeo: (seoReqData) => ({ type: ACTIONS.GET_CURRENT_SEO, seoReqData }),
  setCurrentSeo: data => ({ type: ACTIONS.SET_CURRENT_SEO, data }),
};


export const PROJECT_FILTER = {
  LAST: 'last',
  RANDOM: 'random',
}

const initialState = {
  projects2: {
    all: null,
    brand: null,
    web: null,
    filtered: null,
  },
  projectDetails: {},
  projectKinds: null,
  projectKindSelected: PROJECT_FILTER.LAST,
  headerOptionSelected: '',
  currentSeo: null,
}


function reducer(state={...initialState}, action) {
  switch (action.type) { 
    case HYDRATE: {
      const { main } = action.payload;
     
      return {
        ...state,
        ...main,
      };
    }

    case ACTIONS.SET_PROJECTS: {
      const { type, data } = action.payload;
      const projects2 = {
        ...state.projects2,
        [type]: transform2DataObj(data),
      };
      return {
        ...state,
        projects2,
      }
    }
    
    case ACTIONS.SET_RELATED_PROJECT: {
      const { projects } = action.data;
      return {
        ...state,
        projects2: {
          ...state.projects2,
          filtered: projects,
        }
      }
    }


    case ACTIONS.SET_CURRENT_SEO: 
      return {
        ...state,
        currentSeo: action.data,
      }
      
    case ACTIONS.SET_PROJECTS_KIND:
      return {
        ...state,
        projectKindSelected: action.payload.filter,
      }

    case ACTIONS.SET_HEADER_OPTION:
      return {
        ...state,
        headerOptionSelected: action.option
      }

    case ACTIONS.SET_SINGLE_PROJECT: {
      const { id, data } = action.payload;
      const newProjectDetails = {
        ...state.projectDetails,
        [id]: data,
      }
      return {
        ...state,
        projectDetails: newProjectDetails,
      }
    }



    case ACTIONS.GET_PROJECTS:  return { ...state }
    case ACTIONS.GET_PROJECTS_KIND: return { ...state }
    case ACTIONS.GET_PROJECTS_KIND_DONE: return {
      ...state, 
      projectKinds: [...action.kinds]
    }

    default:
      return state
  }
}

export default reducer
