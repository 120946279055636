export const scrollToElement = (anchor) => {
  try {
    const element = document.getElementById(anchor);
    let point2go = element.offsetTop;
    point2go = point2go - 135;
    window.scrollTo(0, point2go);
  } catch(err) {
    console.log(err);
  }
}

export function htmlEntitiesDecode(input) {
  entities.forEach(function(substitution) {
    const { entity, character } = substitution;
    const regex = new RegExp(entity, 'g');
    input = input.replace(regex, character);
  });
  return input;
}

const entities = [
  { entity: "&Agrave;", character: "À" },
  { entity: "&Aacute;", character: "Á" },
  { entity: "&Acirc;", character: "Â" },
  { entity: "&Atilde;", character: "Ã" },
  { entity: "&Auml;", character: "Ä" },
  { entity: "&Aring;", character: "Å" },
  { entity: "&agrave;", character: "à" },
  { entity: "&aacute;", character: "á" },
  { entity: "&acirc;", character: "â" },
  { entity: "&atilde;", character: "ã" },
  { entity: "&auml;", character: "ä" },
  { entity: "&aring;", character: "å" },
  { entity: "&AElig;", character: "Æ" },
  { entity: "&aelig;", character: "æ" },
  { entity: "&szlig;", character: "ß" },
  { entity: "&Ccedil;", character: "Ç" },
  { entity: "&ccedil;", character: "ç" },
  { entity: "&Egrave;", character: "È" },
  { entity: "&Eacute;", character: "É" },
  { entity: "&Ecirc;", character: "Ê" },
  { entity: "&Euml;", character: "Ë" },
  { entity: "&egrave;", character: "è" },
  { entity: "&eacute;", character: "é" },
  { entity: "&ecirc;", character: "ê" },
  { entity: "&euml;", character: "ë" },
  { entity: "&#131;", character: "ƒ" },
  { entity: "&Igrave;", character: "Ì" },
  { entity: "&Iacute;", character: "Í" },
  { entity: "&Icirc;", character: "Î" },
  { entity: "&Iuml;", character: "Ï" },
  { entity: "&igrave;", character: "ì" },
  { entity: "&iacute;", character: "í" },
  { entity: "&icirc;", character: "î" },
  { entity: "&iuml;", character: "ï" },
  { entity: "&Ntilde;", character: "Ñ" },
  { entity: "&ntilde;", character: "ñ" },
  { entity: "&Ograve;", character: "Ò" },
  { entity: "&Oacute;", character: "Ó" },
  { entity: "&Ocirc;", character: "Ô" },
  { entity: "&Otilde;", character: "Õ" },
  { entity: "&Ouml;", character: "Ö" },
  { entity: "&ograve;", character: "ò" },
  { entity: "&oacute;", character: "ó" },
  { entity: "&ocirc;", character: "ô" },
  { entity: "&otilde;", character: "õ" },
  { entity: "&ouml;", character: "ö" },
  { entity: "&Oslash;", character: "Ø" },
  { entity: "&oslash;", character: "ø" },
  { entity: "&#140;", character: "Œ" },
  { entity: "&#156;", character: "œ" },
  { entity: "&#138;", character: "Š" },
  { entity: "&#154;", character: "š" },
  { entity: "&Ugrave;", character: "Ù" },
  { entity: "&Uacute;", character: "Ú" },
  { entity: "&Ucirc;", character: "Û" },
  { entity: "&Uuml;", character: "Ü" },
  { entity: "&ugrave;", character: "ù" },
  { entity: "&uacute;", character: "ú" },
  { entity: "&ucirc;", character: "û" },
  { entity: "&uuml;", character: "ü" },
  { entity: "&#181;", character: "µ" },
  { entity: "&#215;", character: "×" },
  { entity: "&Yacute;", character: "Ý" },
  { entity: "&#159;", character: "Ÿ" },
  { entity: "&yacute;", character: "ý" },
  { entity: "&yuml;", character: "ÿ" },
  { entity: "&#176;", character: "°" },
  { entity: "&#134;", character: "†" },
  { entity: "&#135;", character: "‡" },
  { entity: "&lt;", character: "<" },
  { entity: "&gt;", character: ">" },
  { entity: "&#177;", character: "±" },
  { entity: "&#171;", character: "«" },
  { entity: "&#187;", character: "»" },
  { entity: "&#191;", character: "¿" },
  { entity: "&#161;", character: "¡" },
  { entity: "&#183;", character: "·" },
  { entity: "&#149;", character: "•" },
  { entity: "&#153;", character: "™" },
  { entity: "&copy;", character: "©" },
  { entity: "&reg;", character: "®" },
  { entity: "&#167;", character: "§" },
  { entity: "&#182;", character: "¶" },
  { entity: "&quot;", character: "\"" },
  { entity: "&nbsp;", character: " " },
  { entity: "&ndash;", character: "-" },
  { entity: "&amp;", character: "&" },
  { entity: "&ldquo;", character: "“" },
  { entity: "&bull;", character: "•" },
  { entity: "&rdquo;", character: "”" },
  { entity: "&ordf;", character: "ª" },
  { entity: "&ordm;", character: "º" },
  { entity: "&ordf;", character: "ª" },
  { entity: "&ordf;", character: "ª" },
  { entity: "&ordf;", character: "ª" },
  { entity: "&ordf;", character: "ª" },
  { entity: "&ordf;", character: "ª" },
];


export const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const getCookie = (cName) => {
  let cValue = document.cookie;
  let cStart = cValue.indexOf(` ${cName}=`);
  if (cStart === -1) {
    cStart = cValue.indexOf(`${cName}=`);
  }
  if (cStart === -1) {
    cValue = null;
  } else {
    cStart = cValue.indexOf('=', cStart) + 1;
    let cEnd = cValue.indexOf(';', cStart);
    if (cEnd === -1) {
      cEnd = cValue.length;
    }
    cValue = unescape(cValue.substring(cStart, cEnd));
  }
  return cValue;
}

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
}

export const setCookie = (cName, value, exdays) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  const cValue = escape(value) + ((exdays === null) ? '' : `;expires=${exdate.toUTCString()};path=/`);
  document.cookie = `${cName}=${cValue}`;
}
