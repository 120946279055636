/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable default-param-last  cambio   */
// const HYDRATE = "__NEXT_REDUX_WRAPPER_HYDRATE__";
import React from 'react';
import dynamic from "next/dynamic";
let Modal = null;
const HYDRATE = "__NEXT_REDUX_WRAPPER_HYDRATE__";

export const types = {
  INIT_ANCHOR_MODAL_LISTENER: "MODAL/INIT_ANCHOR_MODAL_LISTENER",
  ADD_MODAL: "MODAL/ADD_MODAL",
  SET_MODAL_HISTORY: "MODAL/SET_MODAL_HISTORY",
};

export const actions = {
  /* SSEO */
  initAnchorModalListener: () => ({
    type: types.INIT_ANCHOR_MODAL_LISTENER,
  }),
  addModal: (data) => ({
    type: types.ADD_MODAL,
    data,
  }),
  setModalHistory: (data) => ({
    type: types.SET_MODAL_HISTORY,
    data,
  }),
};

const initialState = {
  modalImport: null,
  modalHistory: [],
  Modal: null,
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      const { modals } = action.payload;
      return {
        ...state,
        ...modals,
      };
    }
    case types.ADD_MODAL: {
      if (!state.Modal) {
        const modalImport = import("@/components/Modal");
        Modal = dynamic(() => modalImport, {ssr: false});
        const modalInstance = <Modal />;
        return {
          ...state,
          modalImport,
          Modal: modalInstance,
        };
      }
      return state;
    }
    case types.SET_MODAL_HISTORY: {
      return {
        ...state,
        modalHistory: [...action.data],
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default ModalReducer;
