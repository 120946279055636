/* eslint-disable react-hooks/exhaustive-deps */
import Head from 'next/head';
import { useSelector } from "react-redux";
import { wrapper } from '../store';
import ScrollAnimation from '../components/ScrollAnimation';
import NProgress from 'nprogress';
import { useRouter } from "next/router";
import '../styles/global.css';
import '../styles/animations.css';
import '../styles/nprogress.min.css';
// import { scrollToElement } from '../tools';
import { useEffect } from 'react';


let installSwJustOne = 0;
const installSW = () => {
  try {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(
          registration => {
            // console.log(`ServiceWorker registration successful with scope: ${registration.scope}`);
          },
          error => {
            // console.log(`ServiceWorker registration failed: ${error}`);
          }
        );
      });
    }
  } catch (err) {
    console.log(`err:  ${err}`)
  }
}


let delay = 0.300;
let duration = 0.5;
export const animateContent = (url='/') => {
  setTimeout(() => {
    if (url === '/contacta'){
      delay=0.15
      duration=0.25
    }
    new ScrollAnimation({
      delay,
      duration,
    });
    ScrollAnimation.init();
  }, 350)
}



function App({ Component, pageProps }) {
  const router = useRouter();
  const Modal = useSelector((state) => state.modals.Modal);
  useEffect(() => { 
    animateContent()  
    if (installSwJustOne < 1) {
      installSwJustOne =+ 1;
      installSW()
    }
  }, [])

  const handleRouteChangeStart = (url, { shallow }) => {
    NProgress.start();
  };
  
  const handleRouteChangeComplete = (url, { shallow }) => {
    animateContent(url);
    NProgress.done();
  };

  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events]);
  
  return (
    <>
      <Head>
        <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="Content-Language" content="es" />
        <meta name="robots" content="index, follow" />
        <meta name="theme-color" content="#000000" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="application-name" content="VICDESIGN" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" href="/favicon.ico"/>
        {/* FAVICON */}
        <link rel="apple-touch-icon" sizes="57x57" href="/favicons/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/favicons/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/favicons/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/favicons/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/favicons/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/favicons/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/favicons/apple-touch-icon-152x152.png" />
        <link rel="icon" type="image/png" href="/favicons/favicon-196x196.png" sizes="196x196" />
        <link rel="icon" type="image/png" href="/favicons/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/png" href="/favicons/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/favicons/favicon-16x16.png" sizes="16x16" />
        <link rel="icon" type="image/png" href="/favicons/favicon-128x128.png" sizes="128x128" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicons/favicon.ico" />
        <meta name="msapplication-TileImage" content="favicons/ms-tile-144x144.png" />
        <meta name="msapplication-square70x70logo" content="favicons/ms-tile-70x70.png" />
        <meta name="msapplication-square150x150logo" content="favicons/ms-tile-150x150.png" />
        <meta name="msapplication-wide310x150logo" content="favicons/ms-tile-310x150.png" />
        <meta name="msapplication-square310x310logo" content="favicons/ms-tile-310x310.png" />
        <meta name="msapplication-config" content="config.xml" />
      </Head>
      <Component {...pageProps} />
      {Modal && Modal}
    </>
  )
}

export default wrapper.withRedux(App)
