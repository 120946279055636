import { put, select, call, all, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { slugify, htmlEntitiesDecode } from '@/tools';
import Request from '@gcloud-npm/utils.fetch';
import { actions as mainActions } from '../reducers/main';


export function* fetchProjects(kind, all) {
  let operators = '';

  if(kind || all) { operators = '?'; }
  if (kind) { operators+= `kind=${kind}&`; }
  if (all) { operators+='ALL=1'; }
  
  const request = new Request({
    method: 'GET',
    endpoint: `https://www.vicdesign.es/admin/api/getProjects/index.php${operators}`,
    // headers: headers2Use,
    headers: {'Content-Type': 'application/json;charset=UTF-8' },
  });
  return yield request.makeRequest();
}

export function fetchLegals() {
  const request = new Request({
    method: 'GET',
    endpoint: `https://www.vicdesign.es/admin/api/getLegals/index.php`,
    // headers: headers2Use,
    headers: {'Content-Type': 'application/json;charset=UTF-8' },
  });
  return request.makeRequest();
}

export async function fetchProjects2(kind, all) {
  let operators = '';

  if(kind || all) { operators = '?'; }
  if (kind) { operators+= `kind=${kind}&`; }
  if (all) { operators+='ALL=1'; }

  const request = new Request({
    method: 'GET',
    endpoint: `https://www.vicdesign.es/admin/api/getProjects/index.php${operators}`,
    // headers: headers2Use,
    headers: {'Content-Type': 'application/json;charset=UTF-8' },
  });
  return request.makeRequest();
}

export async function fetchKinds2() {
  const request = new Request({
    method: 'GET',
    endpoint: 'https://www.vicdesign.es/admin/api/getProjectsKinds/index.php',
    // headers: headers2Use,
    headers: {'Content-Type': 'application/json;charset=UTF-8' },
  });
  return request.makeRequest();
}

export function* loadProjects({ params }) {
  try {
    const { kind, all } = params;
    const data = yield call(fetchProjects, kind, all);
    /* NUEVA LOGICA */
    let type = 'all';
    if (kind) {
      type = kind.toLowerCase();
    }
   yield put(mainActions.setProjects(type, data))

  } catch(err) {
    console.log(err);
  }
}

export function* fetchSingleProjects({id}) {
  const request = new Request({
    method: 'GET',
    endpoint: `https://www.vicdesign.es/admin/api/getProject/index.php?idProyecto=${id}`,
    // headers: headers2Use,
    headers: {'Content-Type': 'application/json;charset=UTF-8' },
  });
  return yield request.makeRequest();
}

export function* getProject({id}) {
  try {
    const data = yield call(fetchSingleProjects, { id });
    yield put(mainActions.setSingleProject({ id, data: data[0] }));
  } catch(err) {
    console.log(err);
    yield put(mainActions.setSingleProject([]));
  }
}

export function* fetchKinds() {
  const request = new Request({
    method: 'GET',
    endpoint: 'https://www.vicdesign.es/admin/api/getProjectsKinds/index.php',
    // headers: headers2Use,
    headers: {'Content-Type': 'application/json;charset=UTF-8' },
  });
  return yield request.makeRequest();
}

export function* loadProjectsKinds() {
  try {
    const projectKinds = yield select(state => state.main.projectKinds);
    if (!projectKinds || projectKinds.length === 0){
      let data = yield call(fetchKinds);
      data = data.map(item => {
        const mayusName = item.nombre.toUpperCase();
        return ({
            key: item.idTipoProyecto,
            id: item.idTipoProyecto,
            label: mayusName,
            filter: mayusName,
            link: `/${slugify(item.nombre.toLowerCase())}/`,
        })
      })
      yield put(mainActions.getProjectsKindDone(data));
    }
  } catch(err) {
    console.log(err);
    yield put(mainActions.getProjectsKindDone([]));
  }
}

let string = '';
const transformObject2GetParams = (object, parent='') => {
  const keys = Object.keys(object);
  keys.forEach(key => {
    const currentValue = object[key];
    const isObject = typeof currentValue === 'object';
    if (currentValue && typeof currentValue === 'object') {
      transformObject2GetParams(currentValue, `${key}_`);
    }
    if (!isObject) {
      if (!string) {
        string = `?${parent}${key}=${currentValue || ''}`;
      } else {
        string = `${string}&${parent}${key}=${currentValue || ''}`
      }
    }
  })
}

export async function fetchSEO(seoReq) {
  try {
    transformObject2GetParams(seoReq);
    console.log({
      seoReq,
      string
    })
    const params = `${string}`;
    string = '';
    const endpoint = `https://www.vicdesign.es/admin/api/seo/getSeo.php${params}`;
    const request = new Request({
      method: 'GET',
      endpoint,
      headers: {'Content-Type': 'application/json;charset=UTF-8' }
    });
    return await request.makeRequest();
  } catch(e){
    console.log(e)
  }
}

export function* getSeo({ seoReqData }){
  try {
    const {data} = yield call(fetchSEO, seoReqData);
    yield put(mainActions.setCurrentSeo(data));
  } catch(e) {
    console.error(e)
  }
}


const normalizerQuery = (queryStr) => {
  let res = queryStr.toLowerCase();
  res = htmlEntitiesDecode(res)
  res = res.replace(/á/g, 'a');
  res = res.replace(/é/g, 'e');
  res = res.replace(/í/g, 'i');
  res = res.replace(/ó/g, 'o');
  res = res.replace(/ú/g, 'u');
  return res;
} 


let searchApi = null;
let Search = null;
let searchEngine = null;
let dictionary = {}

function* createSearchEngine(
  info,
  props={
    fieldNamesOrIndexFunction: [
      'normalizedName',
      'searcherKeywordsNormalized'
    ],
  }
) {
  try {
    return eventChannel((emitter) => {
      const iv = 0;
      searchEngine = new searchApi.default({ Search });
      searchEngine.indexResource({
        ...props,
        resources: info,
        resourceName: 'projects'
      });
  
      searchEngine.subscribe(
        (data) => { emitter(data);},
        (error) => { console.log(error)},
      );
      
      // The subscriber must return an unsubscribe function, but it will never happen
      return () => { clearInterval(iv) }
    });
  } catch(error) {
    console.log(error)
  }
}


export function* initSearchApiListener(avList) {
  try {
    const chan = yield call(createSearchEngine, avList);
    while (true) {
      const info = yield take(chan);
      const finalResults = {}
      const { result} = info;
      result.forEach(id => {
        finalResults[id] = dictionary[id];
      })
      yield put(mainActions.setRelatedProject({ projects:finalResults }))
    }
  } catch (error) {
    console.error(error)
  }
}


function normalizerProjectsInfo(allInfo) {
  const res = [];
  Object.values(allInfo).forEach(element => {
    const doppedElement = {
      ...element,
      id: element.idProyect,
      normalizedName: normalizerQuery(element.nombre),
      searcherKeywordsNormalized: normalizerQuery(element.searcherKeywords),
    }
    dictionary[element.idProyect] = doppedElement;
    res.push(doppedElement)
  })

  return res;
}

export function* getRelatedProjects({data}){
  const { query, category } = data;
  try {
    if (!Search && !searchApi){
      const infoNeeded = yield all([
        call(() => import('js-worker-search')),
        call(() => import('./search-api')),
      ]);
      Search = infoNeeded[0].default;
      searchApi = infoNeeded[1];
      const allInfo = yield select(state => state.main.projects2.all)
      yield call(initSearchApiListener, normalizerProjectsInfo(allInfo));
    }

   const normalizedQuery = normalizerQuery(query);
   if (searchEngine && normalizedQuery) {
    searchEngine.performSearch('projects', normalizedQuery);
   }

   if(!normalizedQuery && searchEngine){
    yield put(mainActions.setRelatedProject({ projects:null }))
   }

  } catch(error) {
    console.log(error);
  }
}